import { CloseIcon } from "assets"
import { calcPrice, formatPrice } from "utils/calc-price"
import React from "react"
import { translateLabelFactory } from "utils/common"
import { useTranslation } from "react-i18next"

type ListFilesProps = {
  files: PdfFile[]
  percentCompleted: Obj<number>
  onCancelUpload(file: PdfFile): void
  onClickFile(file: PdfFile): void
  onRemoveFile(id: string): void
}

const ListFiles: React.FC<ListFilesProps> = ({
  files,
  percentCompleted,
  onCancelUpload,
  onRemoveFile,
  onClickFile,
}) => {
  const { t } = useTranslation()
  const translateLabel = translateLabelFactory(t)

  return (
    <div className="grid gap-4 grid-cols-1 p-4">
      {files.map((file) => (
        <div
          className="bg-white relative h-64 flex rounded-lg overflow-hidden shadow-lg grid grid-cols-3"
          key={file.id}
        >
          {file.uploading && (
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-700 bg-opacity-90 flex flex-col items-center justify-center">
              <span className="text-white text-4xl mb-4">
                {percentCompleted[file.id] || 0}%
              </span>
              <span
                className="bg-red-200 text-red-500 py-2 px-4 rounded"
                onClick={() => onCancelUpload(file)}
              >
                {t("btn.cancel")}
              </span>
            </div>
          )}
          <img
            src={file.thumbnail}
            alt={file.filename}
            className="w-full col-span-1"
            onError={() => {
              onRemoveFile(file.id)
            }}
            onClick={async () => {
              onClickFile(file)
            }}
          />
          {/*<div*/}
          {/*  style={{*/}
          {/*    backgroundImage: `url('${file.thumbnail}')`,*/}
          {/*  }}*/}
          {/*  onError={() => {*/}
          {/*    onRemoveFile(file.id)*/}
          {/*  }}*/}
          {/*  className="w-full col-span-1 bg-contain rounded-lg"*/}
          {/*/>*/}
          <div className="p-4 col-span-2 truncate">
            <div className="flex justify-between">
              <div className="truncate">
                <span className="text-black font-bold text-sm font-medium flex-grow">
                  {file.originalFilename}
                </span>
              </div>
              <div
                className="bg-gray-400 p-1 rounded-full"
                onClick={() => {
                  onRemoveFile(file.id)
                }}
              >
                <CloseIcon className="fill-current text-white" />
              </div>
            </div>

            <div className="grid grid-cols-2 mt-2 gap-1">
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.paper_size")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {file.config.paper_size}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.paper_type")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {translateLabel(file.config.paper_type)}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.paper_layout")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {translateLabel(file.config.layout)}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.ink")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {translateLabel(file.config.ink)}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.copies")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {file.config.copies}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.side")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {file.config.side}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.saddle_stitch")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {translateLabel(file.config.saddle_stitch)}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {t("pdf.comb_binding")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {file.config.comb_binding ? t("pdf.yes") : t("pdf.no")}
              </span>
            </div>

            <div className="col-span-3 flex justify-between items-center mt-2">
              <span className="font-medium text-purple-400 text-base">
                {formatPrice(calcPrice(file.config))}
              </span>

              <span
                className="text-xs py-1 px-2 bg-gray-100 text-gray-500 rounded"
                onClick={() => onClickFile(file)}
              >
                Chỉnh sửa
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ListFiles
