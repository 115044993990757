export const getErrorMessage = (e: any) => {
  const res = e.response?.data ?? {}
  let message =
    res?.message || e.message || "Something went wrong. Please try again"
  const code = res?.code || 400

  if (code === 1100) {
    message = "Đơn hàng không tồn tại. Vui lòng thử lại!"
  }

  if (code === 1102) {
    message = "Đơn hàng đã bị huỷ!"
  }

  return {
    code,
    message,
  }
}
