import React from "react"

type NumberInputProps = {
  value: number
  onChange(value: number): void
  // onIncrease(value: number): void
  // onDecrease(value: number): void
}

const NumberInput: React.FC<NumberInputProps> = ({ value, onChange }) => {
  return (
    <div className="flex flex-row w-min h-10 rounded-lg relative bg-transparent mt-1">
      <button
        onClick={() => onChange(value - 1)}
        className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
      >
        <span className="m-auto text-2xl font-thin">−</span>
      </button>
      <input
        type="number"
        className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
        min={1}
        max={200}
        value={value}
        onChange={(e) => {
          const value = parseInt(e.target.value)
          onChange(isNaN(value) ? 0 : value)
        }}
      />
      <button
        className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
        onClick={() => onChange(value + 1)}
      >
        <span className="m-auto text-2xl font-thin">+</span>
      </button>
    </div>
  )
}

export default NumberInput
