import Tag from "components/Tag/Tag"
import Button from "components/Button/Button"
import React, { useCallback, useMemo, useState } from "react"
import { classnames } from "tailwindcss-classnames"
import { useAsync } from "react-use"
import axios from "axios"
import Loading from "components/Loading/Loading"
import dayjs from "dayjs"
import { calcPrice, formatPrice } from "utils/calc-price"
import LoadingModal from "components/Modal/LoadingModal"
import ConfirmModal from "components/Modal/ConfirmModal"
import CancelModal from "components/Modal/CancelModal"
import { getErrorMessage } from "utils/handle-error"
import { AhamoveIcon } from "assets"
import { translateLabelFactory } from "utils/common"
import useQuery from "hooks/useQuery"
import { useTranslation } from "react-i18next"

enum OrderStatus {
  IDLE = "IDLE",
  ASSIGNING = "ASSIGNING",
  ACCEPTED = "ACCEPTED",
  CONFIRMING = "CONFIRMING",
}

const Confirm = () => {
  const qs = useQuery()
  const { t } = useTranslation()
  const translateLabel = translateLabelFactory(t)

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [timing, setTiming] = useState(30)
  const [errorCode, setErrorCode] = useState(400)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const [data, setData] = useState([])
  const [isCancelled, setIsCancelled] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0)
  const currentThumbnail = useMemo(() => data[currentThumbnailIndex], [
    currentThumbnailIndex,
    data,
  ])

  useAsync(async () => {
    try {
      if (!qs.order_id) {
        return
      }

      setLoading(true)
      const order = await axios
        .get<any>(`${process.env.REACT_APP_MINI_APP_URL}/v1/order/detail`, {
          params: {
            order_id: qs.order_id,
            token: "",
          },
        })
        .then((resp) => resp.data)

      setOrder(order)

      // if (isObjectEmpty(data) || data.code === 1100) {
      //   setError(t("error.order_doesnt_exist"))
      //   return
      // }
      //
      // if (data.code === 1101) {
      //   setError(t("error.order_was_accepted"))
      //   return
      // }
      //
      // if (data.code === 1102) {
      //   setError(t("error.order_was_cancelled"))
      //   return
      // }
      //
      // if (data.code) {
      //   setErrorCode(data.code)
      //   setError(data.message)
      //   return
      // }

      // setOrder(data)
      // setTotalPrice(data.path[1].cod ?? 0)

      if (order?.metadata) {
        const data = order.metadata.data
        if (data) {
          setData(data)
        }
      }
    } catch (e) {
      const { code, message } = getErrorMessage(e)
      setErrorCode(code)
      setError(message)
    } finally {
      setLoading(false)
    }
  }, [qs.order_id])

  const onCancel = useCallback(
    async (reason) => {
      try {
        await axios.post(
          `${process.env.REACT_APP_MINI_APP_URL}/v1/order/cancel`,
          {
            order_id: qs.order_id,
            comment: reason,
            token: "",
          }
        )
      } catch (e) {
        const { code, message } = getErrorMessage(e)
        setErrorCode(code)
        setError(message)
      }
    },
    [qs.order_id]
  )

  const onDownload = useCallback(() => {
    if (!order) {
      return
    }

    const win = window.open(order.metadata.signed_url, "_blank")
    win?.focus()
  }, [order])

  const onConfirm = useCallback(async () => {
    try {
      // setShouldConfirm(true)
      // setSubmitLoading(true)
      // setShouldConfirm(false)

      // if (!order) {
      //   throw new Error(t("text.cant_find_order"))
      // }
      setIsLoading(true)

      await axios.post(
        `${process.env.REACT_APP_MINI_APP_URL}/v1/order/confirm`,
        {
          order_id: qs.order_id,
          token: "",
          service_id: order.metadata.service_id,
          process_photo_time: timing - 5,
        }
      )

      setIsAccepted(true)
      onDownload()
      // setSubmitLoading(false)
      // history.push({
      //   pathname: "status",
      //   state: {
      //     status: Status.Succeed,
      //   },
      // })
    } catch (e) {
      const { code, message } = getErrorMessage(e)
      setErrorCode(code)
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }, [order, qs.order_id, onDownload, timing])

  const shouldDownload = useMemo(() => {
    if (isAccepted) {
      return true
    }

    if (order) {
      return [
        OrderStatus.IDLE,
        OrderStatus.ASSIGNING,
        OrderStatus.ACCEPTED,
      ].includes(order.status)
    }

    return false
  }, [order, isAccepted])

  const hasAccepted = useMemo(() => {
    return isAccepted || order?.status !== OrderStatus.CONFIRMING
  }, [isAccepted, order])

  const totalPrice = useMemo(
    () => data.reduce((acc, item) => acc + calcPrice(item.config), 0),
    [data]
  )

  if (error) {
    return (
      <div className="bg-gray-100 h-screen flex justify-center items-center">
        <div className="shadow-xl bg-red-100 bg-opacity-90 max-w-6xl p-4 rounded">
          <h3 className="text-red-500 font-medium text-xl">Đã có lỗi xảy ra</h3>
          {errorCode && <span className="text-red-400">{errorCode}: </span>}
          <span className="text-red-400">{error}</span>
        </div>
      </div>
    )
  }

  if (loading || !order) {
    return (
      <div className="bg-gray-100 h-screen flex justify-center items-center">
        <div className="flex justify-center items-center flex-col max-w-6xl p-4">
          <Loading />
          <span className="text-blue-500 mt-4">
            Đang tìm kiếm đơn hàng. Vui lòng chờ trong giây lát.
          </span>
        </div>
      </div>
    )
  }

  if (isCancelled) {
    return (
      <div className="bg-gray-100 h-screen flex justify-center items-center">
        <div className="shadow-xl bg-green-50 max-w-6xl p-4 rounded">
          <span className="text-green-400">Đơn hàng đã được huỷ!</span>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-gray-100 pb-4">
      <header className="h-16 shadow-lg flex items-center px-4 bg-white fixed inset-x-0 top-0">
        <AhamoveIcon />
        <h2 className="ml-4 font-medium">Xác nhận đơn hàng</h2>
      </header>
      <div className="flex justify-center items-center mt-16">
        <div className="grid grid-cols-1 m-6 sm:grid-cols-2 sm:m-12 rounded-2xl overflow-hidden shadow-xl bg-white max-w-6xl">
          {currentThumbnail && (
            <div>
              <div className="bg-gray-100 h-full">
                <img
                  src={currentThumbnail.thumbnail}
                  alt=""
                  className="w-full h-96 object-contain p-2 bg-black"
                />

                {data.length > 1 && (
                  <div className="flex overflow-x-auto w-full bg-gray-400">
                    {data.map((item, index) => (
                      <img
                        key={item.filename}
                        src={item.thumbnail}
                        alt={item.filename}
                        className={classnames("h-32", "cursor-pointer", "p-2", {
                          "opacity-25": currentThumbnailIndex !== index,
                        })}
                        onClick={() => setCurrentThumbnailIndex(index)}
                      />
                    ))}
                  </div>
                )}

                <div className="grid grid-cols-2 p-4 gap-2">
                  <h3 className="col-span-2 text-xl text-green-500 font-medium underline">
                    Thông tin cần in
                  </h3>
                  <span>Tên tập tin</span>
                  <span>{currentThumbnail.original_filename}</span>
                  <span>Trang cần in</span>
                  <span>
                    {currentThumbnail.config.printing_pages === "all"
                      ? "Tất cả"
                      : currentThumbnail.config.printing_pages}
                  </span>
                  <span>Loại giấy</span>
                  <span>
                    {translateLabel(currentThumbnail.config.paper_type)}
                  </span>
                  <span>Kích cỡ</span>
                  <span>{currentThumbnail.config.paper_size}</span>
                  <span>Hướng</span>
                  <span>{translateLabel(currentThumbnail.config.layout)}</span>
                  <span>Bản sao</span>
                  <span>{currentThumbnail.config.copies}</span>
                  <span>Màu mực in</span>
                  <span>{translateLabel(currentThumbnail.config.ink)}</span>
                  <span>Số mặt</span>
                  <span>{currentThumbnail.config.side}</span>
                  <span>Đóng kim</span>
                  <span>
                    {translateLabel(currentThumbnail.config.saddle_stitch)}
                  </span>
                  <span>Đóng gáy lò xo</span>
                  <span>
                    {currentThumbnail.config.comb_binding ? "Có" : "Không"}
                  </span>
                  <span>Số trang</span>
                  <span>{currentThumbnail.config.num_pages}</span>
                  <span>Ghi chú</span>
                  <span>{order.path[0].remarks}</span>
                </div>
              </div>
            </div>
          )}
          <div className="m-4">
            <h3 className="font-medium text-2xl">
              Bạn có muốn xác nhận đơn hàng này?
            </h3>
            {order && (
              <div className="grid grid-cols-2 mt-4 gap-4">
                <span className="font-medium">Mã đơn hàng</span>
                <span>#{order._id}</span>
                <span className="font-medium">Ngày tạo</span>
                <span>
                  {dayjs.unix(order.create_time).format("DD/MM/YYYY hh:mm")}
                </span>
                {/*<span className="font-medium">Số lượng</span>*/}
                {/*<span>1</span>*/}
                <span className="font-medium">Phí</span>
                <span>{formatPrice(totalPrice)}</span>
                {/*<span className="font-medium">Cần phải chỉnh lại</span>*/}
                {/*<span>*/}
                {/*  <CheckboxButton*/}
                {/*    checked={needEdit}*/}
                {/*    onChecked={(checked) => setNeedEdit(checked)}*/}
                {/*  />*/}
                {/*</span>*/}
                <a
                  href="https://storage.googleapis.com/aha-photolab-document/huong-dan-xac-nhan-don-photocopy.pdf"
                  className="col-span-2 font-medium text-red-500 underline"
                >
                  BẤM VÀO ĐÂY ĐỂ XEM HƯỚNG DẪN XÁC NHẬN ĐƠN HÀNG
                </a>
                {!hasAccepted && (
                  <>
                    <span className="col-span-2 font-medium">
                      Vui lòng chọn thời gian dự kiến trả ảnh
                    </span>
                    <span className="col-span-2 p-4 bg-red-100 text-sm rounded text-red-500 bg-opacity-70">
                      Vui lòng chọn thời gian dự kiến trả ảnh dưới đây (15 - 30
                      - 60 - 90 - 120 phút). Nếu cửa hàng không thể trả ảnh
                      trong thời gian này vui lòng nhấn huỷ đơn. Xin cảm ơn
                    </span>
                    <div className="col-span-2">
                      {[15, 30, 60, 90, 120].map((time) => (
                        <Tag
                          key={time}
                          label={`${time} phút`}
                          value={time.toString()}
                          active={timing === time}
                          onClick={() => setTiming(time)}
                        />
                      ))}
                    </div>
                  </>
                )}
                {hasAccepted && (
                  <div className="col-span-2 bg-green-100 bg-opacity-90 max-w-6xl p-4 rounded">
                    <span className="text-green-500">
                      Bạn đã xác nhận đơn hàng thành công. Vui lòng thực hiện in
                      sản thẩm trong thời gian quy định. Tài xế sẽ đến lấy hàng
                      trong giây lát.
                    </span>
                  </div>
                )}
                {shouldDownload && (
                  <div className="grid col-span-2">
                    <Button
                      bgColor="bg-blue-100"
                      textColor="text-blue-500"
                      onClick={onDownload}
                    >
                      Tải xuống
                    </Button>
                  </div>
                )}
                {!hasAccepted && (
                  <>
                    <Button onClick={() => setIsConfirm(true)}>Đồng ý</Button>
                    <Button
                      bgColor="bg-red-100"
                      textColor="text-red-500"
                      onClick={() => {
                        setShowCancelModal(true)
                      }}
                    >
                      Từ chối
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <CancelModal
        visible={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onSubmit={async (reason) => {
          setIsLoading(true)
          setShowCancelModal(false)
          await onCancel(reason)
          setIsCancelled(true)
          setIsLoading(false)
        }}
      />

      <ConfirmModal
        visible={isConfirm}
        // message={`Bạn có muốn nhận đơn hàng này không và hoàn thành trong vòng ${timing} phút không?`}
        renderMessage={() => (
          <span>
            Bạn có muốn nhận đơn hàng này không và hoàn thành trong vòng{" "}
            <span className="font-bold text-red-500">{timing} phút</span> không?
          </span>
        )}
        cancelTitle="Huỷ"
        confirmTitle="Xác nhận"
        title="Xác nhận"
        onCancel={() => setIsConfirm(false)}
        onConfirm={async () => {
          setIsConfirm(false)
          await onConfirm()
        }}
      />

      <LoadingModal
        active={isLoading}
        message={["Đang xử lí, vui lòng chờ trong giây lát!"]}
      />
    </div>
  )
}

export default Confirm
