import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import useQuery from "hooks/useQuery"

export const useLang = (defaultLang = "vi") => {
  const { i18n } = useTranslation()
  const qs = useQuery()
  const lang = qs.lang ?? defaultLang

  useAsync(async () => {
    await i18n.changeLanguage(lang as string)
  }, [lang, i18n])

  return lang
}
