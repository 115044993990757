import React, { InputHTMLAttributes } from "react"

type InputProps = {} & InputHTMLAttributes<HTMLInputElement>

const Input: React.FC<InputProps> = (props) => {
  return (
    <input
      type="text"
      className="w-full border-2 border-gray-300 p-2 rounded focus:border-green-300 outline-none"
      {...props}
    />
  )
}

export default Input
