import Loading from "components/Loading/Loading"
import React from "react"
import BaseModal from "components/Modal/BaseModal"

type LoadingModalProps = {
  active: boolean
  message?: string[]
}

const LoadingModal: React.FC<LoadingModalProps> = ({ active, message }) => {
  if (!active) {
    return null
  }

  return (
    <BaseModal visible={active}>
      <div className="bg-white flex justify-center items-center flex-col p-4 rounded">
        <Loading />

        {!!message && (
          <div className="text-center mt-4">
            {message.map((msg, index) => (
              <span key={`cancel-${index}`} className="text-black mt-2 block">{msg}</span>
            ))}
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default LoadingModal
