import BaseModal from "components/Modal/BaseModal"
import Button from "components/Button/Button"
import React from "react"
import { classnames } from "tailwindcss-classnames"

type ConfirmModalProps = {
  title: string
  message?: string
  renderMessage?(): JSX.Element
  visible: boolean
  cancelTitle?: string
  confirmTitle: string
  onConfirm(): void
  onCancel?(): void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  visible,
  title,
  message,
  renderMessage,
  confirmTitle,
  cancelTitle,
  onCancel,
  onConfirm,
}) => {
  return (
    <BaseModal visible={visible}>
      <div className="m-8 p-4 bg-white rounded max-h-96">
        <div className="mb-2">
          <span className="whitespace-nowrap mr-2font-bold mb-2 block text-center font-bold">
            {title}
          </span>
          <div className="text-center">
            {!!renderMessage ? (
              renderMessage()
            ) : (
              <span className="">{message}</span>
            )}
          </div>
          <div className="flex-none mt-4">
            <div
              className={classnames("grid", "gap-2", "grid-cols-1", {
                "grid-cols-2": !!cancelTitle,
              })}
            >
              {!!cancelTitle && (
                <Button
                  bgColor="bg-gray-200"
                  textColor="text-gray-400"
                  onClick={onCancel}
                >
                  {cancelTitle}
                </Button>
              )}
              <Button onClick={onConfirm}>{confirmTitle}</Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default ConfirmModal
