import React from "react"
import { classnames } from "tailwindcss-classnames"

type TagProps = {
  label: string
  value: string
  active?: boolean
  onClick?(value: string): void
}

const Tag: React.FC<TagProps> = ({ label, value, active, onClick }) => {
  return (
    <span
      onClick={() => onClick(value)}
      className={classnames(
        "text-base",
        "font-medium",
        "bg-gray-100",
        "py-2",
        "px-3",
        "rounded",
        "text-gray-500",
        "align-middle",
        "mr-2",
        "cursor-pointer",
        "break-words",
        "mb-4",
        "w-auto",
        "inline-block",
        {
          "bg-green-100": active,
          "text-green-500": active,
        }
      )}
    >
      {label}
    </span>
  )
}

export default Tag
