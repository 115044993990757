import React, { ChangeEventHandler } from "react"

type UploadProps = {
  onUpload: ChangeEventHandler<HTMLInputElement>
}

const Upload = React.forwardRef<HTMLInputElement, UploadProps>(
  ({ onUpload }, ref) => {
    return (
      <input
        accept="application/pdf"
        type="file"
        id={"files"}
        name={"files"}
        onChange={(e) => {
          onUpload(e)
          // clear input
          e.target.value = ""
        }}
        multiple
        style={{ display: "none" }}
        ref={ref}
      />
    )
  }
)

export default Upload
