import React, { Suspense, useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import "tailwindcss/tailwind.css"
import "./styles/modal.css"
import Home from "pages/Home/Home"
import Checkout from "pages/Checkout/Checkout"
import Confirm from "pages/Confirm/Confirm"
import { prefetchApps } from "qiankun"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

const history = createBrowserHistory()

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://8dcc13aa9d384d6282179bdea077c403@o426452.ingest.sentry.io/5622326",
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: process.env.NODE_ENV,
    ignoreErrors: [/died in status NOT_MOUNTED/],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
        })
      }

      return event
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

function App() {
  useEffect(() => {
    prefetchApps([
      { name: "mini-checkout", entry: process.env.REACT_APP_MINI_CHECKOUT },
    ])
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Router history={history}>
        <Suspense fallback={<span>Loading...</span>}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <Route exact path="/confirm">
              <Confirm />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </Sentry.ErrorBoundary>
  )
}

export default App
