import React, { useCallback, useMemo, useState } from "react"
import BaseModal from "components/Modal/BaseModal"
import { RadioButton } from "components/RadioButton/RadioButton"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next"

const CancelModal = ({ visible, onSubmit, onCancel }) => {
  const { t } = useTranslation()
  const [reasonIndex, setReasonIndex] = useState(0)
  const [otherReason, setOtherReason] = useState("")

  const reasons = useMemo(() => {
    return [
      t("cancel_reason.dont_guarantee_on_time_printing"),
      t("cancel_reason.document_content_isnt_suitable"),
      t("cancel_reason.cant_ensure_the_quality_of_document"),
      t("cancel_reason.other_reason"),
    ]
  }, [t])

  const onChecked = useCallback((index: number) => {
    setReasonIndex(index)
  }, [])

  return (
    <BaseModal visible={visible}>
      <div className="bg-white w-96 p-4 rounded">
        <h3 className="font-medium">{t("text.reason_for_cancellation")}</h3>
        <h4 className="text-sm text-gray-600">
          {t("text.let_us_know_your_reason")}
        </h4>
        {reasons.map((r, index) => (
          <RadioButton
            name={"reason"}
            title={r}
            key={r}
            checked={reasonIndex === index}
            onChange={() => onChecked(index)}
          />
        ))}
        <textarea
          name="comment"
          id="comment"
          cols={30}
          rows={3}
          className="bg-gray-100 w-full resize-none p-4 rounded"
          placeholder="Để lại lý do cho việc hủy đơn"
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
        <div className="grid grid-cols-2 gap-4">
          <Button
            bgColor="bg-gray-100"
            textColor="text-gray-600"
            onClick={onCancel}
          >
            {t("btn.cancel")}
          </Button>
          <Button
            onClick={() => {
              onSubmit(
                reasonIndex === reasons.length - 1
                  ? otherReason
                  : reasons[reasonIndex]
              )
            }}
          >
            {t("btn.confirm")}
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default CancelModal
