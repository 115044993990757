const rangeToArray = (start, end): number[] => {
  const arr = []

  for (let i = start; i <= end; i++) {
    arr.push(i)
  }

  return arr
}

interface RangesPagesResult {
  status: "error" | "success"
  value: string | number[]
}

export const parseRangesToPages = (
  ranges: string[],
  numPages: number
): RangesPagesResult => {
  if (ranges[0] === "all" || ranges[0] === "") {
    return {
      status: "success",
      value: new Array(numPages),
    }
  }

  const pages: number[] = []

  for (const range of ranges) {
    const split = range.split("-")

    if (split.length > 2) {
      return {
        status: "error",
        value: "invalid_range",
      }
    }

    if (split.length === 2) {
      const start = parseInt(split[0], 10)
      const end = parseInt(split[1], 10)

      if (start < 1) {
        return {
          status: "error",
          value: "invalid_range",
        }
      }

      if (end > numPages) {
        return {
          status: "error",
          value: "invalid_range",
        }
      }

      if (start > end) {
        return {
          status: "error",
          value: "invalid_range",
        }
      }

      pages.push(...rangeToArray(start, end))
    }

    if (split.length === 1) {
      const current = parseInt(split[0], 10)

      if (current < 1) {
        return {
          status: "error",
          value: "invalid_range",
        }
      }

      if (current > numPages) {
        return {
          status: "error",
          value: "invalid_range",
        }
      }

      if (split[0]) {
        pages.push(parseInt(split[0], 10))
      }
    }
  }

  return {
    status: "success",
    value: [...new Set(pages)],
  }
}
