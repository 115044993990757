import { PaperOption } from "common/constants"
import Tag from "components/Tag/Tag"
import React from "react"

type FormControlProps = {
  label: string
  renderRight?(): void
}

export function isActive<T>(selected: T, current: T) {
  return selected === current
}

type FormTagsProps<T> = {
  selected: T
  customIsActive?(current: T): boolean
  options: PaperOption<T>[]
  onClick(value: string): void
}

export function FormTags<T>({
  options,
  selected,
  onClick,
  customIsActive,
}: FormTagsProps<T>) {
  return (
    <>
      {options.map((o) => (
        <Tag
          key={o.value.toString()}
          active={
            customIsActive
              ? customIsActive(o.value)
              : isActive(selected, o.value)
          }
          label={o.label}
          value={o.value.toString()}
          onClick={onClick}
        />
      ))}
    </>
  )
}

export const FormControl: React.FC<FormControlProps> = ({
  label,
  children,
  renderRight,
}) => {
  return (
    <div className="mb-4">
      <div className="flex justify-between">
        <span className="text-sm font-bold font-sans">{label}</span>
        {renderRight && renderRight()}
      </div>
      <div className="my-3">{children}</div>
    </div>
  )
}
