import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  inkOptions,
  layoutOptions,
  paperSizeOptions,
  paperType2Options,
  paperTypeOptions,
  saddleStitchOptions,
  sideOptions,
} from "common/constants"
import { calcPrice, formatPrice } from "utils/calc-price"
import Tag from "components/Tag/Tag"
import NumberInput from "components/NumberInput/NumberInput"
import Button from "components/Button/Button"
import { FormControl, FormTags } from "components/Form/FormControl"
import { translateMapFactory } from "utils/common"
import { CheckboxButton } from "components/CheckboxButton/CheckboxButton"
import Input from "components/Input/Input"
import { parseRangesToPages } from "utils/gen-pages"
import ErrorModal from "components/Modal/ErrorModal"
import { useTranslation } from "react-i18next"

type PriceModalProps = {
  currentFile: PdfFile
  isOpen: boolean
  onClose(): void
  onChangeField: (key: keyof PdfFileConfig) => (value: any) => void
}

const FileSetting: React.FC<PriceModalProps> = ({
  currentFile,
  isOpen,
  onClose,
  onChangeField,
}) => {
  const { t } = useTranslation()
  const translateMap = translateMapFactory(t)
  const [errors, setErrors] = useState<Obj<string>>({})
  const [showModalError, setShowModalError] = useState(false)

  const config = currentFile?.config
  const ink = config?.ink
  const customPaperTypeOptions = useMemo(() => {
    // if (config.paper_size === "A3") {
    //   return [paperTypeOptions.find((gsm) => gsm.value === "normal")]
    // }
    if (ink === "color") {
      return paperType2Options
    }

    return paperTypeOptions
  }, [ink])

  useEffect(() => {
    if (ink === "black-and-white") {
      onChangeField("paper_type")("normal")
    } else {
      onChangeField("paper_type")("text")
    }
  }, [ink, onChangeField])

  const totalPrice = useMemo(() => (config ? calcPrice(config) : 0), [config])
  const formattedPrice = useMemo(() => formatPrice(totalPrice), [totalPrice])

  useEffect(() => {
    if (!config) {
      return
    }

    const result = parseRangesToPages(
      config.printing_pages.split(","),
      config.num_pages
    )

    if (result.status === "error") {
      setErrors((prevState) => ({
        ...prevState,
        printingPages: result.value as string,
      }))
    }
  }, [config])

  const onChangePrintingPages = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setErrors((prevState) => ({
        ...prevState,
        printingPages: "",
      }))
      onChangeField("printing_pages")(e.target.value)
    },
    [onChangeField]
  )

  if (!currentFile || !isOpen) {
    return null
  }

  return (
    <div className="h-full flex flex-col absolute top-0 left-0 right-0 bottom-0 bg-white">
      <div className="flex-none shadow-md px-4 pt-2">
        <div className="mb-4">
          <p className="text-lg font-bold font-sans">{t("pdf.price")}</p>
          <p className="text-4xl font-bold font-sans text-green-500">
            {formattedPrice}
          </p>
        </div>
      </div>

      <div className="w-full h-full overflow-y-scroll">
        <div className="py-2 px-4">
          <FormControl label={t("pdf.copies")}>
            <NumberInput
              value={config.copies}
              onChange={(value) =>
                onChangeField("copies")(value < 1 ? 1 : value)
              }
              // onDecrease={(value) => onChangeQuantity(value)}
              // onIncrease={(value) => onChangeQuantity(value)}
            />
          </FormControl>

          <FormControl label={t("pdf.num_pages")}>
            <Tag
              active={false}
              label={config.num_pages.toString()}
              value={config.num_pages.toString()}
              onClick={() => {}}
            />
          </FormControl>
          <FormControl label={t("pdf.side")}>
            <FormTags
              selected={config.side}
              options={sideOptions}
              onClick={(value) => onChangeField("side")(parseInt(value))}
            />
          </FormControl>
          <FormControl label={t("pdf.paper_size")}>
            <FormTags
              selected={config.paper_size}
              options={paperSizeOptions}
              onClick={onChangeField("paper_size")}
            />
          </FormControl>
          <FormControl label={t("pdf.ink")}>
            <FormTags
              selected={config.ink}
              options={translateMap(inkOptions)}
              onClick={onChangeField("ink")}
            />
          </FormControl>
          <FormControl label={t("pdf.paper_type")}>
            <FormTags
              selected={config.paper_type}
              options={translateMap(customPaperTypeOptions)}
              onClick={onChangeField("paper_type")}
            />
          </FormControl>
          <FormControl label={t("pdf.paper_layout")}>
            <FormTags
              selected={config.layout}
              options={translateMap(layoutOptions)}
              onClick={onChangeField("layout")}
            />
          </FormControl>
          <FormControl
            label={t("pdf.printing_pages")}
            // renderRight={() => (
            //   <CheckboxButton
            //     checked={config.printing_pages !== "all"}
            //     onChecked={() => {
            //       onChangeField("printing_pages")(
            //         config.printing_pages !== "all" ? "all" : ""
            //       )
            //     }}
            //   />
            // )}
          >
            <Tag
              active={config.printing_pages === "all"}
              label={t("pdf.all")}
              value={"all"}
              onClick={() => {
                setErrors({})
                onChangeField("printing_pages")("all")
              }}
            />
            <Tag
              active={config.printing_pages !== "all"}
              label={t("pdf.printing_pages")}
              value={"1"}
              onClick={() => {
                onChangeField("printing_pages")("1")
              }}
            />
            {config.printing_pages !== "all" && (
              <div>
                <Input
                  value={config.printing_pages}
                  onChange={onChangePrintingPages}
                  placeholder={t("text.please_enter_these_printing_pages")}
                />
                {errors.printingPages && (
                  <span className="text-sm text-red-500">
                    {t("error.invalid_range")}
                  </span>
                )}
              </div>
            )}
          </FormControl>
          <FormControl
            label={t("pdf.saddle_stitch")}
            renderRight={() => (
              <CheckboxButton
                checked={config.saddle_stitch !== "none"}
                onChecked={() => {
                  onChangeField("saddle_stitch")(
                    config.saddle_stitch !== "none" ? "none" : "hard"
                  )
                }}
              />
            )}
          >
            {config.saddle_stitch !== "none" && (
              <FormTags
                selected={config.saddle_stitch}
                options={translateMap(saddleStitchOptions).filter(
                  (el) => el.value !== "none"
                )}
                onClick={onChangeField("saddle_stitch")}
              />
            )}
          </FormControl>
          <FormControl
            label={t("pdf.comb_binding")}
            renderRight={() => (
              <CheckboxButton
                checked={config.comb_binding}
                onChecked={() => {
                  onChangeField("comb_binding")(!config.comb_binding)
                }}
              />
            )}
          />
          {/*<FormControl label={t("pdf.saddle_stitch")}>*/}
          {/*  <FormTags*/}
          {/*    selected={config.saddle_stitch}*/}
          {/*    options={translateMap(saddleStitchOptions)}*/}
          {/*    onClick={onChangeField("saddle_stitch")}*/}
          {/*  />*/}
          {/*</FormControl>*/}
          <FormControl label={t("pdf.note")}>
            <textarea
              name="note"
              rows={5}
              value={config.note}
              onChange={(e) => {
                onChangeField("note")(e.target.value)
              }}
              className="rounded-lg resize-none w-full h-full border-purple-200 border-2 outline-none p-4"
            />
          </FormControl>
        </div>
      </div>

      <div className="flex-none">
        <div className="grid gap-2 grid-cols-1 p-2">
          <Button
            onClick={() => {
              const isValid = Object.values(errors).every((o) => !o)

              if (!isValid) {
                setShowModalError(true)
              } else {
                onClose()
              }
            }}
          >
            {t("btn.confirm")}
          </Button>
        </div>
      </div>

      <ErrorModal
        visible={showModalError}
        cancelTitle={t("btn.close")}
        title={t("text.error")}
        message={t("error.please_try_again")}
        onCancel={() => {
          setShowModalError(false)
        }}
      />
    </div>
  )
}

export default FileSetting
