class Store {
  userInfo = {
    name: "",
    mobile: "",
    feature: {
      address: "",
      lat: 0,
      lng: 0,
    },
  }
  token = ""
  files: PdfFile[] = []
}

const store = new Store()

export default store
