export interface PaperOption<T = string> {
  value: T
  label: string
}

export const paperSizeOptions: PaperOption[] = [
  {
    value: "A0",
    label: "A0",
  },
  {
    value: "A1",
    label: "A1",
  },
  {
    value: "A2",
    label: "A2",
  },
  {
    value: "A3",
    label: "A3",
  },
  {
    value: "A4",
    label: "A4",
  },
]

export const paperTypeOptions: PaperOption[] = [
  {
    value: "normal",
    label: "normal",
  },
  {
    value: "good",
    label: "good",
  },
]

export const paperType2Options: PaperOption[] = [
  {
    value: "text",
    label: "text",
  },
  {
    value: "photo",
    label: "photo",
  },
]

export const sideOptions: PaperOption<number>[] = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
]

export const inkOptions: PaperOption[] = [
  {
    value: "black-and-white",
    label: "black-and-white",
  },
  {
    value: "color",
    label: "color",
  },
]

export const saddleStitchOptions: PaperOption[] = [
  {
    value: "none",
    label: "none",
  },
  {
    value: "both",
    label: "both",
  },
  {
    value: "hard",
    label: "hard",
  },
  {
    value: "glass",
    label: "glass",
  },
]

export const layoutOptions: PaperOption[] = [
  {
    value: "landscape",
    label: "landscape",
  },
  {
    value: "portrait",
    label: "portrait",
  },
]

export const bookBindingOptions: PaperOption[] = [
  {
    value: "none",
    label: "none",
  },
  {
    value: "needle_sealing",
    label: "needle_Sealing",
  },
  {
    value: "spring_binding",
    label: "spring_binding",
  },
]

export const printingPageOptions: PaperOption[] = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "1",
    label: "printing_pages",
  },
]
