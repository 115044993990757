import BaseModal, { BaseModalProps } from "components/Modal/BaseModal"
import React from "react"
import Button from "components/Button/Button"

type ErrorModalProps = {
  title: string
  message: string
  onCancel(): void
  cancelTitle: string
} & BaseModalProps

const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  message,
  visible,
  onCancel,
  cancelTitle,
}) => {
  return (
    <BaseModal visible={visible}>
      <div className="m-8 p-4 bg-red-50 rounded w-full">
        <div className="mb-2">
          <span className="whitespace-nowrap mr-2font-bold mb-2 block text-center font-medium text-red-500">
            {title}
          </span>
          <div className="text-center">
            <span className="text-red-400">{message}</span>
          </div>
          <div className="flex-none mt-4">
            <div className="grid grid-cols-1">
              <Button
                onClick={() => {
                  onCancel()
                }}
              >
                {cancelTitle}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default ErrorModal
