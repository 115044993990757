import { parseRangesToPages } from "utils/gen-pages"

const saddleStitchPrices = {
  A0: {
    none: 0,
    hard: 40_000,
    glass: 40_000,
    both: 80_000,
  },
  A1: {
    none: 0,
    hard: 15_000,
    glass: 20_000,
    both: 35_000,
  },
  A2: {
    none: 0,
    hard: 10_000,
    glass: 10_000,
    both: 20_000,
  },
  A3: {
    none: 0,
    hard: 5_000,
    glass: 5_000,
    both: 10_000,
  },
  A4: {
    none: 0,
    hard: 2_000,
    glass: 2_000,
    both: 4_000,
  },
}

const calcSaddleStitchPrice = (paperSize: string, saddleStitch: string) => {
  return saddleStitchPrices[paperSize][saddleStitch]
}

const paperPrices = {
  A0: {
    "black-and-white": {
      normal: 10_000,
      good: 10_000,
    },
    color: {
      text: 45_000,
      photo: 50_000,
    },
  },
  A1: {
    "black-and-white": {
      normal: 5_000,
      good: 5_500,
    },
    color: {
      text: 27_000,
      photo: 35_000,
    },
  },
  A2: {
    "black-and-white": {
      normal: 3_000,
      good: 3_500,
    },
    color: {
      text: 15_000,
      photo: 18_000,
    },
  },
  A3: {
    "black-and-white": {
      normal: 900,
      good: 900,
    },
    color: {
      text: 7_000,
      photo: 8_000,
    },
  },
  A4: {
    "black-and-white": {
      normal: 300,
      good: 300,
    },
    color: {
      text: 3_000,
      photo: 3_500,
    },
  },
}

const calcCombBindingPrice = (numPages: number, combBinding: boolean) => {
  if (!combBinding) {
    return 0
  }

  switch (combBinding) {
    case numPages < 50:
      return 10_000
    case numPages < 100:
      return 13_000
    case numPages < 150:
      return 16_000
    case numPages < 200:
      return 20_000
    case numPages < 250:
      return 25_000
    case numPages < 300:
      return 31_000
    case numPages < 350:
      return 36_000
    case numPages < 400:
      return 43_000
    case numPages < 450:
      return 50_000
    default:
      return 0
  }
}

const calcPaperPrice = (
  paperSize: string,
  paperType: string,
  ink: string,
  numPages: number
) => {
  const basePrice = paperPrices[paperSize][ink][paperType]

  if (ink === "color") {
    return basePrice
  }

  if (numPages >= 200) {
    return basePrice
  }

  if (numPages > 100) {
    return basePrice * 2
  }

  return basePrice * 3
}

const calcNumPages = (numPages: number, side: number) =>
  Math.round(numPages / side)

export const calcPrice = (config: PdfFileConfig) => {
  const selectedPrintingPages = parseRangesToPages(
    config.printing_pages.split(","),
    config.num_pages
  )
  const numPages = calcNumPages(
    selectedPrintingPages.value.length > 0
      ? selectedPrintingPages.value.length
      : config.num_pages,
    config.side
  )
  const paperPrice = calcPaperPrice(
    config.paper_size,
    config.paper_type,
    config.ink,
    numPages
  )
  const combBindingPrice = calcCombBindingPrice(numPages, config.comb_binding)
  const saddleStitchPrice = calcSaddleStitchPrice(
    config.paper_size,
    config.saddle_stitch
  )

  return (
    (paperPrice * numPages + combBindingPrice + saddleStitchPrice) *
    config.copies
  )
}

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("vn", {
    style: "currency",
    currency: "VND",
  }).format(price)
}
