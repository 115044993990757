import { CloseIcon } from "assets"
import Button from "components/Button/Button"
import React from "react"
import { useTranslation } from "react-i18next"

type FilePreviewProps = {
  file: PdfFile
  onClose(): void
  onClickSetting(): void
}

const FileReview: React.FC<FilePreviewProps> = ({
  file,
  onClose,
  onClickSetting,
}) => {
  const { t } = useTranslation()
  if (!file) {
    return null
  }

  return (
    <div className="h-full flex flex-col absolute top-0 left-0 right-0 bottom-0 bg-white">
      <div className="p-2 flex items-center">
        <span className="w-full">{file.filename}</span>
        <div className="p-4" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      {file && (
        <iframe
          title="preview"
          src={file.fileUrl}
          className="w-full h-full"
          frameBorder="0"
          onLoadStart={() => {
            // console.log("start load")
          }}
          onLoadedData={() => {
            // console.log("end load")
          }}
        />
      )}

      <div className="flex-none">
        <div className="grid gap-2 grid-cols-1 p-2">
          <Button onClick={() => onClickSetting()}>{t("btn.settings")}</Button>
        </div>
      </div>
    </div>
  )
}

export default FileReview
