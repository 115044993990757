import FileSetting from "pages/Home/components/FileSetting"
import FileReview from "pages/Home/components/FilePreview"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import useWindowSize from "hooks/useWindowSize"
import { useTranslation } from "react-i18next"
import axios, { CancelTokenSource } from "axios"
import { useHistory } from "react-router-dom"
import { nanoid } from "nanoid"
import * as pdfjsLib from "pdfjs-dist"
import { urlToFile } from "utils/file"
import { calcPrice, formatPrice } from "utils/calc-price"
import Upload from "components/Upload/Upload"
import ListFiles from "pages/Home/components/ListFiles"
import Button from "components/Button/Button"
import store from "store"
import useQuery from "hooks/useQuery"
import queryString from "query-string"
import { useLang } from "hooks/useLang"

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"

const Home: React.FC<any> = (props) => {
  useLang()

  const size = useWindowSize()
  const { t } = useTranslation()
  const history = useHistory()
  const uploadRef = useRef<HTMLInputElement>(null)
  const qs = useQuery()

  const [files, setFiles] = useState<PdfFile[]>([])
  const [percentCompleted, setPercentCompleted] = useState<Obj<number>>({})
  const [currentFile, setCurrentFile] = useState<PdfFile | null>(null)
  const [showConfig, setShowConfig] = useState(false)
  const [sources, setSources] = useState<Map<string, CancelTokenSource>>(
    new Map()
  )

  useEffect(() => {
    const localFiles = localStorage.getItem("photocopyFiles")

    if (localFiles) {
      setFiles(JSON.parse(localFiles))
    }
  }, [])

  const onSelectFile = useCallback(() => {
    if (!uploadRef.current) {
      return
    }

    uploadRef.current.click()
  }, [uploadRef])

  const readPdf = useCallback((file: File) => {
    if (file.type !== "application/pdf") {
      return
    }
    const id = nanoid()

    const fileReader = new FileReader()

    fileReader.onload = async function () {
      const typedArray = new Uint8Array(this.result as ArrayBuffer)

      const pdf = await pdfjsLib.getDocument(typedArray).promise
      const numPages = pdf.numPages
      const page = await pdf.getPage(1)

      const viewport = page.getViewport({ scale: 1 })

      const canvas = document.createElement("canvas")
      canvas.width = viewport.width
      canvas.height = viewport.height
      const scale = Math.min(
        canvas.width / viewport.width,
        canvas.height / viewport.height
      )

      await page.render({
        canvasContext: canvas.getContext("2d") as any,
        viewport: page.getViewport({ scale }),
      }).promise

      const thumbnail = canvas.toDataURL("image/png")

      setFiles((prevState) =>
        prevState.concat({
          id,
          file,
          thumbnail,
          ext: "pdf",
          filename: file.name,
          originalFilename: file.name,
          uploading: true,
          config: {
            num_pages: numPages,
            comb_binding: false,
            layout: "landscape",
            ink: "black-and-white",
            paper_size: "A4",
            paper_type: "normal",
            saddle_stitch: "none",
            printing_pages: "all",
            copies: 1,
            side: 1,
            note: "",
          },
        })
      )

      const imageFile = await urlToFile(thumbnail, id, "image/png")
      // const newPdfFile = renameFile(file, id)

      const formData = new FormData()
      formData.append(id, file)
      formData.append(id, imageFile)

      const source = axios.CancelToken.source()
      setSources((prevState) => {
        prevState.set(id, source)

        return prevState
      })
      const data: [UploadResult, UploadResult] = await axios({
        url: process.env.REACT_APP_UPLOADER_URL + "/v2/upload",
        method: "POST",
        data: formData,
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
          "Application-Type": "photocopy",
          "Application-Stage": process.env.REACT_APP_STAGE,
        },
        onUploadProgress(e) {
          const percentCompleted = Math.round((e.loaded * 100) / e.total)

          setPercentCompleted((prevState) => ({
            ...prevState,
            [id]: percentCompleted || 0,
          }))
        },
      }).then((result) => result.data)

      setFiles((prevState) => {
        const newState = prevState.map((o) => {
          if (o.id === id) {
            return {
              ...o,
              file: null,
              uploading: false,
              ext: data[0].ext,
              filename: data[0].hash,
              fileUrl: data[0].signedUrl,
              thumbnail: data[1].signedUrl,
            }
          }

          return o
        })

        localStorage.setItem("photocopyFiles", JSON.stringify(newState))

        return newState
      })
    }

    fileReader.readAsArrayBuffer(file)
  }, [])

  const onUpload = useCallback(
    (e) => {
      const files = [...e.target.files]

      files.forEach((file) => {
        readPdf(file)
      })
    },
    [readPdf]
  )

  const onChangeConfig = useCallback((key: keyof PdfFileConfig) => {
    return (value: any) => {
      setCurrentFile((prevState) => {
        if (!prevState) {
          return prevState
        }

        return {
          ...prevState,
          config: {
            ...prevState.config,
            [key]: value,
          },
        }
      })
    }
  }, [])

  const onCloseConfig = () => {
    setShowConfig(false)
    setFiles((prevState) => {
      const newState = prevState.map((o) => {
        if (o.id === currentFile?.id) {
          return currentFile
        }

        return o
      })

      localStorage.setItem("photocopyFiles", JSON.stringify(newState))

      return newState
    })
    setCurrentFile(null)
  }

  const isEnable = useMemo(() => {
    return files.length > 0 && files.some((f) => !f.uploading)
  }, [files])

  const onRemoveFile = useCallback((id: string) => {
    setFiles((prevState) => {
      const newState = prevState.filter((o) => o.id !== id)

      localStorage.setItem("photocopyFiles", JSON.stringify(newState))

      return newState
    })
  }, [])

  const totalFee = useMemo(
    () => files.reduce((acc, item) => acc + calcPrice(item.config), 0),
    [files]
  )

  return (
    <div
      style={{ height: size.height }}
      className="flex flex-col h-full relative overflow-hidden bg-pink-100 bg-opacity-50"
    >
      <Upload onUpload={onUpload} ref={uploadRef} />
      <div className="flex-grow overflow-y-scroll">
        <ListFiles
          files={files}
          percentCompleted={percentCompleted}
          onCancelUpload={(file) => {
            const source = sources.get(file.id)

            if (source) {
              source.cancel("Cancel by user")
            }

            onRemoveFile(file.id)
          }}
          onRemoveFile={onRemoveFile}
          onClickFile={(file) => setCurrentFile(file)}
        />
      </div>
      <div className="flex-none relative">
        <div
          style={{
            boxShadow: "rgb(197 158 182 / 63%) -1px -5px 9px 1px",
            height: 0.1,
          }}
          className="absolute top-0 inset-x-0"
        />
        <div className="grid gap-2 grid-cols-2 p-2 bg-white p-4 rounded-t-2xl shadow-lg">
          <div className="col-span-2 flex justify-between text-sm">
            <span className="text-gray-500">{t("text.total")}</span>
            <span className="text-gray-700">{formatPrice(totalFee)}</span>
          </div>
          <Button onClick={onSelectFile}>{t("btn.upload")}</Button>
          <Button
            disabled={!isEnable}
            onClick={() => {
              // props.setState({ files })
              // const parsed = queryString.parse(props.location.search)
              store.files = files
              store.token = qs.token as string
              history.push({
                pathname: "/checkout",
                search: queryString.stringify({
                  token: qs.token,
                }),
              })
            }}
          >
            {t("btn.next")}
          </Button>
        </div>
      </div>

      {currentFile && (
        <FileReview
          file={currentFile}
          onClose={() => setCurrentFile(null)}
          onClickSetting={() => setShowConfig(true)}
        />
      )}

      {currentFile && (
        <FileSetting
          onClose={onCloseConfig}
          isOpen={showConfig}
          currentFile={currentFile}
          onChangeField={onChangeConfig}
        />
      )}
    </div>
  )
}

export default Home
