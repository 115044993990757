import { useEffect, useRef, useState } from "react"
import { loadMicroApp, MicroApp } from "qiankun"
import store from "store"
import { calcPrice } from "utils/calc-price"
import LoadingModal from "components/Modal/LoadingModal"

const Checkout = () => {
  const containerRef = useRef()
  const microApp = useRef<MicroApp>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const items = store.files.map((f) => ({
      name: f.originalFilename,
      num: f.config.copies,
      price: calcPrice(f.config),
    }))

    const data = store.files.map((f) => ({
      config: {
        type: "PHOTOCOPY",
        ...f.config,
      },
      file_url: f.fileUrl,
      ext: f.ext,
      filename: f.filename,
      original_filename: f.originalFilename,
      thumbnail: f.thumbnail,
      price: calcPrice(f.config),
    }))

    microApp.current = loadMicroApp({
      name: "mini-checkout",
      entry: process.env.REACT_APP_MINI_CHECKOUT,
      container: containerRef.current,
      props: {
        service: "PHOTOCOPY",
        token: store.token,
        items,
        data,
        onBookingDone(order) {
          localStorage.removeItem("photocopyFiles")
        },
      },
    })

    const timer = setInterval(() => {
      if (microApp.current) {
        const status = microApp.current.getStatus()

        if (status !== "LOADING_SOURCE_CODE") {
          setLoading(false)
          clearInterval(timer)
        }
      }
    }, 500)

    return () => microApp.current?.unmount()
  }, [])

  return (
    <div>
      <LoadingModal active={loading} />
      <div ref={containerRef} />
    </div>
  )
}

export default Checkout
