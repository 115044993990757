import React from "react"
import {
  classnames,
  TBackgroundColor,
  TTextColor,
} from "tailwindcss-classnames"

type ButtonProps = {
  onClick?(): any
  bgColor?: TBackgroundColor
  textColor?: TTextColor
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  bgColor,
  textColor,
  disabled,
}) => {
  return (
    <button
      onClick={disabled ? null : onClick}
      type="button"
      disabled={disabled}
      className={classnames(
        "transition",
        "duration-300",
        "outline-none",
        "ease-in-out",
        // "focus:outline-none",
        // "focus:shadow-outline",
        // "hover:bg-purple-900",
        "text-white",
        "font-normal",
        "py-2",
        "px-4",
        "rounded-2xl",
        {
          [bgColor]: disabled ? false : !!bgColor,
          [textColor]: disabled ? false : !!textColor,
        },
        {
          "bg-gray-300": disabled,
          "text-gray-500": disabled,
        }
      )}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  bgColor: "bg-purple-500",
}

export default Button
